import { Routes } from '@angular/router';
import { roleGuard } from '../../auth/guards';
import { AppPermissions } from '../../auth/models/app-permissions';

export const adminPagesRoutes: Routes = [
  {
    path: 'cityhalls',
    title: 'City Halls',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./cityhalls-page/cityhalls-page.component').then(
        (c) => c.CityhallsPageComponent
      ),
  },
  {
    path: 'companies',
    title: 'Companies',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./companies-page/companies-page.component').then(
        (c) => c.CompaniesPageComponent
      ),
  },
  {
    path: 'invoice-levels',
    title: 'Invoice Levels',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./invoice-levels-page/invoice-levels-page.component').then(
        (c) => c.InvoiceLevelsPageComponent
      ),
  },
  {
    path: 'population-levels',
    title: 'Population Levels',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import(
        './population-levels-page/population-levels-page.component'
      ).then((c) => c.PopulationLevelsPageComponent),
  },
  {
    path: 'sectors',
    title: 'Sectors',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./sectors-page/sectors-page.component').then(
        (c) => c.SectorsPageComponent
      ),
  },
  {
    path: 'communities',
    title: 'Communities',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./communities-page/communities-page.component').then(
        (c) => c.CommunitiesPageComponent
      ),
  },
  {
    path: 'communities-types',
    title: 'Communities Types',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import(
        './communities-types-page/communities-types-page.component'
      ).then((c) => c.CommunitiesTypesPageComponent),
  },
  {
    path: 'indicators',
    title: 'Indicators',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./indicators-page/indicators-page.component').then(
        (c) => c.IndicatorsPageComponent
      ),
  },
  {
    path: 'characteristics',
    title: 'Characteristics',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import(
        './characteristics-page/characteristics-page.component'
      ).then((c) => c.CharacteristicsPageComponent),
  },
  {
    path: 'users',
    title: 'Users',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./users-page/users-page.component').then(
        (c) => c.UsersPageComponent
      ),
  },
  {
    path: 'activities',
    title: 'Activities',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./activities-page/activities-page.component').then(
        (c) => c.ActivitiesPageComponent
      ),
  },
  {
    path: 'resources',
    title: 'Resources',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./resources-page/resources-page.component').then(
        (c) => c.ResourcesPageComponent
      ),
  },
  {
    path: 'assistances',
    title: 'Assistance Requests',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./assistances-page/assistances-page.component').then(
        (c) => c.AssistancesPageComponent
      ),
  },
  {
    path: 'inscriptions',
    title: 'Inscriptions',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    loadComponent: async () =>
      await import('./inscriptions-page/inscriptions-page.component').then(
        (c) => c.InscriptionsPageComponent
      ),
  },

];
