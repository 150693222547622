import { Routes } from '@angular/router';
import { AppPermissions } from '../../auth/models/app-permissions';
import { roleGuard } from '../../auth/guards';

export const mainPagesRoutes: Routes = [
  {
    path: 'companies/me',
    title: 'Company',
    data: {
      roles: [AppPermissions.COMPANY],
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./company-main-page/company-main-page.component').then(
        (c) => c.CompanyMainPageComponent
      ),
  },
  {
    path: 'cityhalls/me',
    title: 'City Hall',
    data: {
      roles: [AppPermissions.CITYHALL],
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./city-hall-main-page/city-hall-main-page.component').then(
        (c) => c.CityHallMainPageComponent
      ),
  },
  {
    path: 'xunta',
    title: 'Xunta',
    data: {
      roles: [AppPermissions.XUNTA],
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./xunta-main-page/xunta-main-page.component').then(
        (c) => c.XuntaMainPageComponent
      ),
  },
];
